import React, { Suspense, useEffect } from 'react';
import { useRoutes, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import { getNotificationsList } from 'store/notification/notificationSlice';
import FCMNotification from 'components/FCMNotification';

const App = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { userEmailForForgotPwd, isOTPPinMatched } = useSelector((state) => state.forgotPassword);

  const isPinOTPMatched = isOTPPinMatched();
  const routing = useRoutes(Themeroutes(user, userEmailForForgotPwd, isPinOTPMatched));
  const location = useLocation();
  const navigate = useNavigate();

  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);  
  
  useEffect(() => {
    localStorage.setItem('pinMatched', false);
    if(user){
      dispatch(getNotificationsList(user.id));
    }
    const permission = Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        <ThemeSelector />
        {routing}
        <FCMNotification />
      </div>
    </Suspense>
  );
};

export default App;
