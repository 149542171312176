import React, { useEffect, useState } from 'react';
import { onMessageListener } from 'firebase/firebase';
import { notification } from 'antd';

const FCMNotification = () => {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const unsubscribe = onMessageListener()
      .then((payload) => {
        api.info({
          message: payload.notification.title,
          description: payload.notification.body,
          placement: 'topRight',
          duration: 5
        });
      })
      .catch((err) => console.log('failed: ', err));

    return () => unsubscribe;
  }, []);

  return <>{contextHolder}</>;
};

export default FCMNotification;