import { all } from 'redux-saga/effects';
import authSaga from './auth/authSaga';
import settingsSaga from './settings/settingsSaga';
import profileSaga from './profile/profileSaga';
import dashboardSaga from './dashboard/dashboardSaga';
import favoriteSaga from './favorite/favoriteSaga';
import bookingsSaga from './bookings/bookingsSaga';
import commonSaga from './commons/commonsSaga';
import forgotPwdSaga from './forgotpwd/forgotpwdSaga';
import notificationSaga from './notification/notificationSaga';
import businessSaga from './businesses/businessesSaga';
import orderBookingSaga from './orderBooking/orderBookingSaga';
import bookingScheduleSaga from './bookingReschedule/bookingRescheduleSaga';
import serviceReviewSaga from './serviceReview/serviceReviewSaga';
import businessReviewSaga from './businessesReview/businessesReviewSaga';
import specialistScheduleSaga from './serviceEssentials/serviceEssentialsSaga';
import bookServicesRootSaga from './bookService/bookServiceSaga';
import messagesSaga from './chat/chatSaga';
import { promotionsSaga } from './promotions/promotionsSaga';
import cartSaga from './cart/cartSaga';
export default function* rootSaga() {
  yield all([
    authSaga(),
    settingsSaga(),
    profileSaga(),
    commonSaga(),
    dashboardSaga(),
    favoriteSaga(),
    notificationSaga(),
    orderBookingSaga(),
    bookingsSaga(),
    forgotPwdSaga(),
    businessSaga(),
    bookingScheduleSaga(),
    serviceReviewSaga(),
    businessReviewSaga(),
    specialistScheduleSaga(),
    bookServicesRootSaga(),
    messagesSaga(),
    promotionsSaga(),
    cartSaga()
  ]);
}
