import { call, put, takeLatest, all } from 'redux-saga/effects';
import { $authHost } from 'store/axios';
import { getStripSecret, getStripSecretSuccess, getStripSecretFailure } from './cartSlice'

function* retrieveSecretSaga(action) {
    const { paymentIntentId } = action.payload;

    try {
        const response = yield $authHost.get(`/strip/retrieve-secret/${paymentIntentId}`);
        yield put(getStripSecretSuccess(response.data.data.client_secret));
    } catch (error) {
        yield put(getStripSecretFailure());
    }
}

function* watchRetrieveSecret() {
    yield takeLatest(getStripSecret.type, retrieveSecretSaga);
}

export default function* cartSaga() {
    yield all([
        watchRetrieveSecret(),
    ]);
}