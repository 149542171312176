import { call, put, takeLatest } from 'redux-saga/effects';
import { $host, $authHost } from '../axios';
import {
  getServiceSpecialists,
  getServiceSpecialistsSuccess,
  bookServicesRequest,
  bookServicesSuccess,
  bookServicesFailure,
} from './bookServiceSlice';
import { clearCart, addPaymentData } from 'store/cart/cartSlice';
import { savePaymentIntentToSessionStorage } from 'utils/sessionStorage';

function* fetchServiceSpecialists(action) {
  const { service_id } = action.payload;
  try {
    const response = yield call(
      $authHost.get,
      `/business-services/services-specialists/${service_id}`,
    );
    yield put(getServiceSpecialistsSuccess(response.data.data.specialists));
  } catch (error) {
    if (error.response && error.response.data) {
      console.error('Error fetching service specialists:', error);
    }
  }
}

function* bookServicesSaga(action) {
  const { cartItems, payload, api, navigate, promo } = action.payload;
  const bookServiceData = {
    ...payload,
    ...(promo.discount && { promo_discount: promo.discount }),
  };

  try {
    const response = yield call($authHost.post, 'booking/book-services', bookServiceData);

    if (response.data.code === 400) {
      api.error({
        description: response.data.description,
      });
    }

    const { order_id, payment_intent, ephemeral_key, amount, currency } = response.data.data;
    if (response.data.code === 200) {
      const paymentIntentData = {
        order_id,
        amount_paid: amount,
        promo,
        total_charges: payload.total_charges,
        currency,
        payment_intent,
        items: cartItems,
      };

      yield put(clearCart());
      savePaymentIntentToSessionStorage(paymentIntentData);
      yield put(addPaymentData(paymentIntentData));
      navigate('/pay-now', {
        // state: {
        //   amount_paid: payload.amount_paid,
        //   total_charges: payload.total_charges,
        //   booking_id: order_id,
        //   promo,
        // },
      });
    }
  } catch (error) {
    api.error({
      description: error.response?.data?.description,
    });
    yield put(bookServicesFailure(error.response?.data?.message || 'Booking failed'));
  }
}

export default function* bookServicesRootSaga() {
  yield takeLatest(bookServicesRequest.type, bookServicesSaga);
  yield takeLatest(getServiceSpecialists.type, fetchServiceSpecialists);
}
