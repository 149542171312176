import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { store } from './store/Store';
import { LocationProvider } from 'contextApi/LocationContext';
import { StripeProvider } from 'contextApi/stripe';
import App from './App';
import './data';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((err) => {
      console.log('Service Worker registration failed:', err);
    });
}

ReactDOM.render(
  <Provider store={store}>
    <LocationProvider>
      <StripeProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StripeProvider>
    </LocationProvider>
  </Provider>,

  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
